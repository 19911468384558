import { Highlight, PageLoader, Typography, VStack } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useSubscriptionFsmState } from '../../../../fsm/context';
import { useBillingInformation } from '../../../../hooks/useBillingInformation';
import { getAmountDetails } from '../../../../utils/price';
import cbIcon from './assets/logo-cb.png';
import styles from './styles.module.css';

export function UpFrontPaymentHighLight(): ReactNode {
  const {
    context: { formulePickedObject, paymentPeriodicity },
  } = useSubscriptionFsmState();

  const billingData = useBillingInformation();

  if (!billingData) {
    return (
      <div className={styles.LoaderContainer}>
        <PageLoader />
      </div>
    );
  }

  const isAnnualPayment = billingData.upFrontPaymentInMonths === 12;
  const totalAmount = billingData.upFrontPaymentTotalAmount.formatted;

  const amountDetails = getAmountDetails(billingData, formulePickedObject?.name, paymentPeriodicity);

  return (
    <Highlight>
      <img src={cbIcon} alt="cb logo" />
      <VStack space="kitt.2">
        <Typography.Text variant="bold">
          {isAnnualPayment
            ? `Un paiement de ${totalAmount} sera effectué par carte bancaire à l'étape suivante.`
            : `Un premier paiement de ${totalAmount} sera effectué par carte bancaire à l'étape suivante, les autres seront effectués par prélèvements.`}
        </Typography.Text>

        <Typography.Text base="body-small" color="black-anthracite">
          {amountDetails}
        </Typography.Text>
      </VStack>
    </Highlight>
  );
}
